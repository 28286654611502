import React, { memo, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
  ShieldExclamationIcon,
  ArrowLeftOnRectangleIcon,
  BriefcaseIcon,
  Square3Stack3DIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { TaskContext } from '../context/TaskContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Sidebar = memo(function Sidebar() {
  const { user, logout } = useContext(AuthContext);
  const { t, i18n } = useTranslation(undefined, {
    useSuspense: true, 
    wait: true
  });  
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [imageError, setImageError] = useState(false);
  const { newTasksCount, shouldAnimate } = useContext(TaskContext);
  const [isInitialized, setIsInitialized] = useState(false);
  const isFounder = user?.entity?.subscription_tier === 'founder';
  console.log(isFounder);
  console.log('Current tier:', user?.entity?.subscription_tier);

  // Initialize language settings and wait until language is ready
  useEffect(() => {
    const loadLanguage = async () => {
      const cachedLanguage = localStorage.getItem('i18nextLng') || 'en';
      try {
        await Promise.all([
          i18n.loadNamespaces('translation'),
          i18n.changeLanguage(cachedLanguage)
        ]);
        setIsInitialized(true);
      } catch (error) {
        console.error('Failed to load translations:', error);
      }
    };

    loadLanguage();
    
    const timer = setInterval(() => setCurrentDateTime(new Date()), 60000);
    return () => clearInterval(timer);
  }, [i18n]);

  // Memoize the navigation array to prevent recalculations
  const navigation = useMemo(() => {
    const baseNavigation = [
      { name: 'sidebar.dashboard', to: '/dashboard', icon: HomeIcon, disabled: false },
      {
        name: 'sidebar.tasks',
        to: '/tasks',
        icon: BriefcaseIcon,
        disabled: false,
        badge: newTasksCount > 0 ? `${newTasksCount}` : null,
      },
      { 
        name: 'sidebar.reports', 
        to: '/reports', 
        icon: ChartPieIcon, 
        disabled: false, 
        badge: isFounder ? 'Pro' : null 
      },
      { name: 'sidebar.alerts', to: '/alerts', icon: ShieldExclamationIcon, disabled: true },
    ];

    if (user?.entity_type === 'Company') {
      baseNavigation.push({ name: 'sidebar.wizards', to: '/wizards', icon: Square3Stack3DIcon, disabled: false });
    }

    if (user?.entity_type === 'Portfolio') {
      baseNavigation.push({ name: 'sidebar.portfolio', to: '/portfolio', icon: DocumentDuplicateIcon, disabled: false });
    }

    if (user?.role === 'admin' || user?.role === 'owner') {
      baseNavigation.push({ name: 'sidebar.team', to: '/team', icon: UsersIcon, disabled: false });
    }

    baseNavigation.push({ name: 'sidebar.settings', to: '/settings', icon: AdjustmentsHorizontalIcon, disabled: false });

    if (user?.role === 'owner') {
      baseNavigation.push({
        name: 'sidebar.billing',
        to: '/billing',
        icon: CreditCardIcon,
        disabled: false,
      });
    }

    baseNavigation.push({
      name: 'sidebar.logout',
      to: '#',
      icon: ArrowLeftOnRectangleIcon,
      action: () => setOpen(true),
      noActive: true,
      disabled: false,
    });

    return baseNavigation;
  }, [user, newTasksCount, isFounder]);

  // Use callback to prevent handleLogout function from changing on each render
  const handleLogout = useCallback(() => {
    logout();
    navigate('/logout-success');
  }, [logout, navigate]);

  const formatDateTime = (date) => {
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}, ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  function UserInitials({ firstName, lastName }) {
    const initials = `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase();
    return (
      <div className="h-8 w-8 rounded-full bg-secondary-color flex items-center justify-center">
        <span className="text-white font-bold">{initials}</span>
      </div>
    );
  }

  function fixAvatarUrl(url) {
    if (!url) return url;

    // Define the malformed hosts and the corresponding correct hosts
    const replacements = [
      {
        malformed: 'dev.cenedril.net,%20dev.cenedril.net',
        correct: 'dev.cenedril.net',
      },
      {
        malformed: 'cenedril.net,%20cenedril.net',
        correct: 'cenedril.net',
      },
      {
        malformed: 'staging.cenedril.net,%20staging.cenedril.net',
        correct: 'staging.cenedril.net',
      },
    ];

    // Iterate over each replacement and fix the URL if needed
    for (const { malformed, correct } of replacements) {
      if (url.includes(malformed)) {
        return url.replace(malformed, correct);
      }
    }

    return url;
  }

  if (!isInitialized) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  
  return (
    <>
      <div className="sidebar hidden md:flex flex-col gap-y-5 bg-primary-color px-6 w-64">
        <div className="flex flex-col items-center justify-center pt-10">
          <img className="h-16 w-auto mb-5" src="/LogoOneColor_white.svg" alt="Cenedril Logo" />
          <div className="text-white text-opacity-50 text-xs text-center">
            {formatDateTime(currentDateTime)}
            <br />
            v 0.2
            <br />
            <a href="mailto:feedback@cenedril.net?subject=Cenedril Feedback:" className="text-white underline">
              {t('giveFeedback')}
            </a>
          </div>
        </div>
        <nav className="flex flex-1 flex-col mt-3">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    {item.disabled ? (
                      <span className="text-gray-400 group flex gap-x-3 rounded-md p-2 text-sm font-normal leading-6 cursor-not-allowed">
                        <item.icon className="h-6 w-6 shrink-0 text-gray-400" aria-hidden="true" />
                        {t(item.name)}
                      </span>
                    ) : (
                      <NavLink
                        to={item.to}
                        className={({ isActive }) =>
                          classNames(
                            item.noActive
                              ? 'text-white hover:bg-secondary-color hover:bg-opacity-50'
                              : isActive
                              ? 'bg-secondary-color text-white'
                              : 'text-white hover:bg-secondary-color hover:bg-opacity-50',
                            'sidebar-link group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 relative'
                          )
                        }
                        onClick={item.action}
                      >
                        <item.icon
                          className={classNames(
                            'h-6 w-6 shrink-0',
                            { 'text-white': item.current, 'text-white group-hover:text-white': !item.current }
                          )}
                          aria-hidden="true"
                        />
                        {t(item.name)}
                        {item.badge && (
                          <div className="absolute right-2 flex items-center">
                            {shouldAnimate && (
                              <span className="absolute inline-flex h-full w-full rounded-full bg-secondary-color opacity-75 animate-ping" />
                            )}
                            <span className="relative bg-secondary-color text-white px-2 py-0.5 mt-0.5 rounded-full text-xs">
                              {item.badge}
                            </span>
                          </div>
                        )}
                      </NavLink>
                    )}
                  </li>
                ))}
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <div className="sidebar-link flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white">
                {user?.avatar_url && !imageError ? (
                  <img
                    className="h-8 w-8 rounded-full bg-secondary-color object-cover object-center"
                    src={fixAvatarUrl(user.avatar_url)}
                    alt={`${user.first_name} ${user.last_name}`}
                    onError={() => setImageError(true)}
                  />
                ) : (
                  <UserInitials firstName={user?.first_name} lastName={user?.last_name} />
                )}
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">
                  {user?.first_name} {user?.last_name}
                </span>
              </div>
            </li>
          </ul>
        </nav>
      </div>

      <Transition.Root show={open} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t('logoutConfirmation')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t('logoutConfirmationText')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleLogout}
                    >
                      {t('logoutConfirm')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}, arePropsEqual);

// Custom comparison function for memoization
function arePropsEqual(prevProps, nextProps) {
  return prevProps.user === nextProps.user && prevProps.newTasksCount === nextProps.newTasksCount;
}

export default Sidebar;
