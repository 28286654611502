import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import axios from '../../axios';
import RadarChart from './visualizations/RadarChart';
import BarChart from './visualizations/BarChart';
import BoxPlotChart from './visualizations/BoxPlotChart';
import BarChartComparison from './visualizations/BarChartComparison';
import CollectionDetailsCard from './CollectionDetailsCard';
import TrafficLightTable from './visualizations/TrafficLightTable';
import TrafficLightCard from './visualizations/TrafficLightCard';
import StockColumnChart from './visualizations/StockColumnChart';
import GroupedColumnChart from './visualizations/GroupedColumnChart';
import Column100Chart from './visualizations/Column100Chart';
import ColorTextChart from './visualizations/ColorTextChart';
import ComparisonSelector from './ComparisonSelector';
import { getTranslatedValue } from '../../utils/templateTranslations';
import { ChevronDownIcon, ChevronUpIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import ApexCharts from 'apexcharts';
import { Document, Page, PDFViewer, pdf } from '@react-pdf/renderer';
import html2canvas from 'html2canvas';
import ReportPDF from './ReportPDF';

// Collapsible Section Component
const CollapsibleSection = ({ title, children, defaultOpen = false, isFirst, isLast }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    return (
        <div className={`border-x border-gray-200 
        ${isFirst ? 'border-t rounded-t-lg' : ''} 
        ${isLast ? 'border-b rounded-b-lg' : 'border-b'}`}
        >
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`w-full flex justify-between items-center mb-0 px-4 py-3 bg-white hover:bg-gray-50
            ${isFirst ? 'rounded-t-lg' : ''}`}
            >
                <h2 className="text-xl font-bold mb-0 pb-0 text-gray-900">{title}</h2>
                {isOpen ? (
                    <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                    <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                )}
            </button>
            {isOpen && (
                <div className="p-4">
                    {children}
                </div>
            )}
        </div>
    );
};

const DataCollectionReport = () => {
    const { accessToken } = useParams();
    const { t } = useTranslation();
    const [collection, setCollection] = useState(null);
    const [template, setTemplate] = useState(null);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [comparableCollections, setComparableCollections] = useState([]);
    const [selectedComparison, setSelectedComparison] = useState([]);
    const [comparisonData, setComparisonData] = useState([]);
    const reportRef = useRef(null);
    const [isExporting, setIsExporting] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.get('/api/v1/live_data/user_data', {
                    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
                });
                setUserData(userResponse.data);

                const collectionResponse = await axios.get(`/api/v1/data_collections/report/${accessToken}`, {
                    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
                });

                setCollection(collectionResponse.data.collection);
                setTemplate(collectionResponse.data.template);
                setComparableCollections(collectionResponse.data.comparable_collections || []);

                // Log out to see the data structure
            } catch (err) {
                console.error('Error during data fetch:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accessToken]);

    const handleComparisonSelect = async (comparisonAccessTokens) => {
        if (!comparisonAccessTokens || comparisonAccessTokens.length === 0) {
            setSelectedComparison([]);
            setComparisonData([]);
            return;
        }

        try {
            const comparisonResults = [];
            for (const token of comparisonAccessTokens) {
                const response = await axios.get(`/api/v1/data_collections/report/${accessToken}`, {
                    headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` },
                    params: { compare_with: token }
                });
                comparisonResults.push(response.data.comparison);

                // Log each comparison as we fetch it
            }

            setSelectedComparison(comparisonAccessTokens);
            setComparisonData(comparisonResults);
            // Log the final array of comparisons
        } catch (err) {
            console.error('Error fetching comparison data:', err);
        }
    };

    const getCompleteUsersList = (collection, partId = null) => {
        if (collection.collection_type === 'collaborative') {
            // Get active users from current assignments
            const activeUsers = new Set();
            if (partId) {
                const partAssignment = collection.part_assignments?.[partId] || {};
                (partAssignment.editors || []).forEach(editor => activeUsers.add(editor));
                (partAssignment.submitters || []).forEach(submitter => activeUsers.add(submitter));
            } else {
                Object.values(collection.part_assignments || {}).forEach(assignment => {
                    (assignment.editors || []).forEach(editor => activeUsers.add(editor));
                    (assignment.submitters || []).forEach(submitter => activeUsers.add(submitter));
                });
            }

            // Get deleted users from deleted_assignments
            const deletedUsers = [];
            const deletedAssignments = partId
                ? { [partId]: collection.deleted_assignments?.[partId] }
                : (collection.deleted_assignments || {});

            Object.entries(deletedAssignments).forEach(([_partId, assignments]) => {
                ['editors', 'submitters'].forEach(role => {
                    (assignments?.[role] || []).forEach(user => {
                        if (!deletedUsers.some(u => u.id === user.id)) {
                            deletedUsers.push({
                                id: user.id,
                                user_name: `${user.name} (${t('deleted')})`,
                                was_deleted: true
                            });
                        }
                    });
                });
            });

            // Convert active users to array format
            const activeUsersList = Array.from(activeUsers).map(userId => {
                const user = collection.respondents.find(r => r.id === userId);
                return user ? {
                    id: userId,
                    user_name: user.user_name,
                    was_deleted: false
                } : null;
            }).filter(Boolean);

            return [...activeUsersList, ...deletedUsers];
        }

        // For individual collections
        return collection.respondents;
    };

    const PartAssignments = ({ part, collection, t }) => {
        if (!collection.part_assignments?.[part.id]) {
            return null;
        }

        if (collection.collection_type === 'collaborative') {
            const assignments = collection.part_assignments[part.id];
            // Get deleted users for this part
            const deletedUsers = collection.deleted_assignments?.[part.id] || {};
            const deletedEditors = deletedUsers.editors || [];
            const deletedSubmitters = deletedUsers.submitters || [];

            // Format editors list (combining active and deleted)
            const formattedEditors = [
                ...(assignments.editors || []).map(userId => {
                    const user = collection.respondents.find(r => r.id === userId);
                    return user ? { name: user.user_name, isDeleted: false } : null;
                }).filter(Boolean),
                ...deletedEditors.map(editor => ({ name: editor.name, isDeleted: true }))
            ];

            // Format submitters list (combining active and deleted)
            const formattedSubmitters = [
                ...(assignments.submitters || []).map(userId => {
                    const user = collection.respondents.find(r => r.id === userId);
                    return user ? { name: user.user_name, isDeleted: false } : null;
                }).filter(Boolean),
                ...deletedSubmitters.map(submitter => ({ name: submitter.name, isDeleted: true }))
            ];

            return (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Editors')}</h3>
                        <div className="text-sm text-gray-600">
                            {formattedEditors.map((editor, index) => (
                                <span
                                    key={`${editor.name}-${index}`}
                                    className={editor.isDeleted ? 'italic text-gray-500' : ''}
                                >
                                    {editor.name}{editor.isDeleted ? ` (${t('deleted')})` : ''}
                                    {index < formattedEditors.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Submitters')}</h3>
                        <div className="text-sm text-gray-600">
                            {formattedSubmitters.map((submitter, index) => (
                                <span
                                    key={`${submitter.name}-${index}`}
                                    className={submitter.isDeleted ? 'italic text-gray-500' : ''}
                                >
                                    {submitter.name}{submitter.isDeleted ? ` (${t('deleted')})` : ''}
                                    {index < formattedSubmitters.length - 1 ? ', ' : ''}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }

        // Handle individual collections
        const assignments = collection.part_assignments[part.id];
        const deletedAssignments = collection.deleted_assignments?.[part.id]?.assignees || [];

        const formattedAssignees = [
            ...(assignments.assignees || []).map(userId => {
                const user = collection.respondents.find(r => r.id === userId);
                return user ? { name: user.user_name, isDeleted: false } : null;
            }).filter(Boolean),
            ...deletedAssignments.map(user => ({ name: user.name, isDeleted: true }))
        ];

        return (
            <div>
                <h3 className="text-sm font-medium text-gray-500 mb-2">{t('Assignees')}</h3>
                <div className="text-sm text-gray-600">
                    {formattedAssignees.map((assignee, index) => (
                        <span
                            key={`${assignee.name}-${index}`}
                            className={assignee.isDeleted ? 'italic text-gray-500' : ''}
                        >
                            {assignee.name}{assignee.isDeleted ? ` (${t('deleted')})` : ''}
                            {index < formattedAssignees.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </div>
            </div>
        );
    };

    // Helper function to capture chart as image
    const getChartImage = async (chartId) => {
        const chartInstance = ApexCharts.getChartByID(chartId);
        if (!chartInstance) return null;

        try {
            const result = await chartInstance.dataURI();
            return result.imgURI;
        } catch (error) {
            console.error(`Error capturing chart ${chartId}:`, error);
            return null;
        }
    };

    // Helper function to capture DOM element as image
    const getDOMElementImage = async (element) => {
        if (!element) return null;

        try {
            const canvas = await html2canvas(element, {
                scale: 2, // Higher quality
                logging: false,
                useCORS: true,
                backgroundColor: null
            });
            return canvas.toDataURL('image/png');
        } catch (error) {
            console.error('Error capturing DOM element:', error);
            return null;
        }
    };

    const handleExport = async () => {
        if (!reportRef.current || isExporting) return;
        setIsExporting(true);
    
        try {
            // Add delay to ensure charts are rendered
            await new Promise(resolve => setTimeout(resolve, 500));
    
            const elementsToCapture = reportRef.current.querySelectorAll('[data-capture-for-pdf="true"]');
            console.log('Found elements to capture:', {
                count: elementsToCapture.length,
                elements: Array.from(elementsToCapture).map(el => ({
                    id: el.getAttribute('data-pdf-id'),
                    type: el.getAttribute('data-pdf-id')?.split('-')[0]
                }))
            });
    
            const chartImages = {};
            const complexImages = {};
    
            // Process each element
            for (const element of elementsToCapture) {
                const elementId = element.getAttribute('data-pdf-id');
                console.log('Processing element:', { elementId });
    
                if (elementId?.startsWith('chart-')) {
                    // Try multiple ways to get the chart instance
                    const chartEl = element.querySelector('.apexcharts-canvas');
                    if (chartEl) {
                        // Try direct ID first
                        let chartInstance = ApexCharts.getChartByID(elementId);
                        
                        // If not found, try the apex ID
                        if (!chartInstance) {
                            const apexId = chartEl.id.replace('apexcharts', '');
                            chartInstance = ApexCharts.getChartByID(apexId);
                        }
                        
                        // If still not found, try getting it from the canvas element
                        if (!chartInstance && chartEl.__chartjs__) {
                            chartInstance = chartEl.__chartjs__.instances[0];
                        }
    
                        console.log('Chart instance attempts:', { 
                            directId: !!ApexCharts.getChartByID(elementId),
                            apexId: !!chartInstance,
                            element: !!chartEl.__chartjs__
                        });
    
                        if (chartInstance) {
                            try {
                                const result = await chartInstance.dataURI();
                                console.log('Chart capture successful:', { elementId });
                                chartImages[elementId] = result.imgURI;
                            } catch (err) {
                                console.error('Chart capture failed:', { elementId, error: err });
                            }
                        }
                    }
                } else {
                    // Capture other elements
                    try {
                        console.log('Capturing complex element:', { elementId });
                        const canvas = await html2canvas(element, {
                            scale: 2,
                            logging: true,
                            useCORS: true,
                            backgroundColor: null
                        });
                        complexImages[elementId] = canvas.toDataURL('image/png');
                        console.log('Complex element capture successful:', { elementId });
                    } catch (err) {
                        console.error('Complex element capture failed:', { elementId, error: err });
                    }
                }
            }
    
            // Log capture results
            console.log('Capture complete:', {
                charts: Object.keys(chartImages),
                complex: Object.keys(complexImages)
            });
    
            const translations = {
                integratedAnalysis: String(t('Integrated Analysis')),
                segmentedAnalysis: String(t('Segmented Analysis')),
                biggestTakeaways: String(t('Biggest Takeaways')),
                top5: String(t('Top 5')),
                bottom5: String(t('Bottom 5')),
                current: String(t('Current')),
                comparison: String(t('Comparison')),
                topDebaters: String(t('Top Debaters')),
                average: String(t('Average')),
                maxScores: String(t('max scores')),
                minScores: String(t('min scores')),
                deleted: String(t('deleted')),
                exportedFrom: String(t('Exported from Cenedril on')),
                by: String(t('by')),
                collectionPeriod: String(t('Collection Period')),
                started: String(t('Started')),
                reportViewers: String(t('Report Viewers')),
                dataSources: String(t('Data Sources')),
                editors: String(t('Editors')),
                submitters: String(t('Submitters')),
                assignees: String(t('Assignees'))
            };

            // Generate PDF
            console.log('Generating PDF with captured images');
            const pdfDoc = await pdf((
                <ReportPDF
                    collection={collection}
                    template={template}
                    userData={userData}
                    comparisonData={comparisonData}
                    translations={translations}
                    capturedImages={{
                        ...chartImages,
                        ...complexImages
                    }}
                    comparisonGroupQuestions={comparisonGroupQuestions}
                    userLang={userLang}
                />
            )).toBlob();
    
            // Trigger download with specific name
            const fileName = `${getTranslatedValue(template.name, userLang)}-${collection.period_value}.pdf`;
            const safeFileName = fileName.replace(/[/\\?%*:|"<>]/g, '-');
            
            console.log('Initiating download:', { safeFileName });
            
            const url = URL.createObjectURL(pdfDoc);
            const link = document.createElement('a');
            link.href = url;
            link.download = safeFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
    
            console.log('Export process complete');
    
        } catch (error) {
            console.error('Export process failed:', error);
            console.log('Error details:', error.message);
        } finally {
            setIsExporting(false);
        }
    };

    if (loading) return <div className="text-center p-4">{t('Loading...')}</div>;
    if (error) return <div className="text-center p-4 text-red-600">{t('Error loading report')}</div>;
    if (!collection || !template) return <div className="text-center p-4">{t('No data found')}</div>;

    const userLang = userData?.language?.slice(0, 2) || 'en';

    // Get unique comparison groups and their representative questions
    const getComparisonGroupQuestions = () => {
        const groups = new Set();
        const questions = [];

        template.parts.forEach(part => {
            part.questions.forEach(question => {
                if (question.comparison_group && !groups.has(question.comparison_group)) {
                    groups.add(question.comparison_group);
                    questions.push(question);
                }
            });
        });

        return questions;
    };

    // Group questions by part for departmental analysis
    const partAnalyses = template.parts.map(part => {
        const questions = part.questions.filter(q => {
            return q.analysis_types?.some(type => {
                const shouldInclude = type.includes('bar')
                    || type === 'internal_traffic_light'
                    || type === 'stock_over_time_as_multiplecolumn_chart'
                    || type === 'comparison_over_time_as_multiplebar_chart'
                    || type === 'comparison_over_time_as_column_100';
                if (shouldInclude) {
                }
                return shouldInclude;
            });
        });
        return {
            part,
            questions
        };
    });

    const comparisonGroupQuestions = getComparisonGroupQuestions();

    return (
        <div className="w-full mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">
                    {getTranslatedValue(template.name, userLang)}
                </h1>
                {/* <button
                    onClick={handleExport}
                    disabled={isExporting}
                    className={`
                        inline-flex items-center gap-2 px-4 py-2 
                        text-sm font-medium rounded-lg
                        bg-white border border-gray-300
                        text-gray-700 hover:bg-gray-50 
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                        disabled:opacity-50 disabled:cursor-not-allowed
                        transition-colors duration-200
                    `}
                >
                    <DocumentArrowDownIcon className="h-5 w-5" />
                    {isExporting ? t('Exporting...') : t('Export PDF')}
                </button> */}
            </div>

            <div ref={reportRef}>
                <CollectionDetailsCard
                    collection={collection}
                    template={template}
                    userLang={userLang}
                    t={t}
                />

                <ComparisonSelector
                    comparableCollections={comparableCollections}
                    onComparisonSelect={handleComparisonSelect}
                    selectedComparisons={selectedComparison}
                    currentPeriodValue={collection.period_value}
                    currentPeriodType={collection.period_type}
                    t={t}
                />

                {/* Cross-departmental Analysis Section */}
                <section className="mb-12">
                    <h2 className="text-3xl font-bold">{t('Integrated Analysis')}</h2>

                    {/* Text Analysis Cards */}
                    <div className="space-y-8 mb-12">
                        {template.parts.reduce((allQuestions, part) => {
                            const textQuestions = part.questions.filter(q =>
                                q.analysis_types?.some(type => type.startsWith('text_'))
                            );
                            return [...allQuestions, ...textQuestions];
                        }, []).map((question, index) => (
                            <ColorTextChart
                                key={`text-${question.id}-${index}`}
                                question={question}
                                collection={collection}
                                comparisonData={comparisonData}
                                language={userLang}
                            />
                        ))}
                    </div>

                    {/* Radar Charts - only for questions with radar analysis type */}
                    {comparisonGroupQuestions
                        .filter(question =>
                            question.analysis_types?.includes('comparison_external_as_radar_chart')
                        ).length > 0 && (
                            <div className="space-y-8 mb-12">
                                {comparisonGroupQuestions
                                    .filter(question =>
                                        question.analysis_types?.includes('comparison_external_as_radar_chart')
                                    )
                                    .map((question, index) => (
                                        <RadarChart
                                            key={`radar-${question.comparison_group}-${index}`}
                                            question={question}
                                            collection={collection}
                                            template={template}
                                            comparisonData={comparisonData}
                                        />
                                    ))}
                            </div>
                        )}

                    {/* Traffic Light Table - only for collaborative collections */}
                    {collection.collection_type === 'collaborative' && (
                        <div className="space-y-8 mb-12">
                            {(() => {
                                const allQuestions = template.parts.reduce((allQuestions, part) => {
                                    const partQuestions = part.questions.filter(q =>
                                        // Only include questions that are compatible with traffic light visualization
                                        q.analysis_types?.includes('summary_external_as_traffic_lights') &&
                                        // Ensure we're only getting questions of types that can be compared
                                        ['radio', 'boolean', 'percentage'].includes(q.type)
                                    );
                                    return [...allQuestions, ...partQuestions];
                                }, []);

                                return (
                                    <TrafficLightTable
                                        questions={allQuestions}
                                        collection={collection}
                                        comparisonData={comparisonData}
                                        language={userLang}
                                    />
                                );
                            })()}
                        </div>
                    )}

                    {/* Box Plot / Bar Charts - for all comparison groups */}
                    <div className="space-y-8">
                        {comparisonGroupQuestions
                            .filter(question =>
                                question.analysis_types?.includes('comparison_external_as_boxplot')
                            )
                            .map((question, index) => {
                                if (collection.collection_type === 'individual') {
                                    return (
                                        <BoxPlotChart
                                            key={`boxplot-${question.comparison_group}-${index}`}
                                            question={question}
                                            collection={collection}
                                            template={template}
                                            comparisonData={comparisonData} // Add this line
                                        />
                                    );
                                } else {
                                    return (
                                        <BarChartComparison
                                            key={`bar-comparison-${question.comparison_group}-${index}`}
                                            question={question}
                                            collection={collection}
                                            template={template}
                                            comparisonData={comparisonData} // Add this line
                                        />
                                    );
                                }
                            })}
                    </div>
                </section>

                {/* Segmented Analysis Section */}
                <section>
                    <h2 className="text-3xl font-bold mb-2">{t('Segmented Analysis')}</h2>
                    {partAnalyses.map(({ part, questions }, index) => (
                        <CollapsibleSection
                            key={part.id}
                            title={getTranslatedValue(part.name, userLang)}
                            isFirst={index === 0}
                            isLast={index === partAnalyses.length - 1}
                        >
                            {/* Part Assignments */}
                            {collection.part_assignments?.[part.id] && (
                                <div className="bg-gray-50 rounded-lg p-4 mb-6">
                                    <PartAssignments
                                        part={part}
                                        collection={collection}
                                        t={t}
                                    />
                                </div>
                            )}
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                {questions.map((question) => (
                                    <div key={question.id} className={question.analysis_types.some(type =>
                                        type === 'summary_external_as_traffic_lights'
                                    ) ? 'lg:col-span-1' : 'lg:col-span-2'}>
                                        {question.analysis_types.map((analysisType) => {
                                            if (analysisType === 'comparison_internal_as_bar_chart_desc') {
                                                return (
                                                    <BarChart
                                                        key={`${question.id}-bar`}
                                                        question={question}
                                                        collection={collection}
                                                    />
                                                );
                                            }
                                            if (analysisType === 'summary_external_as_traffic_lights' && collection.collection_type === 'collaborative') {
                                                return (
                                                    <TrafficLightCard
                                                        key={`${question.id}-traffic`}
                                                        question={question}
                                                        collection={collection}
                                                        comparisonData={comparisonData}
                                                        language={userLang}
                                                    />
                                                );
                                            }
                                            if (analysisType === 'stock_over_time_as_multiplecolumn_chart') {
                                                return (
                                                    <StockColumnChart
                                                        key={`${question.id}-stock`}
                                                        question={question}
                                                        collection={collection}
                                                        comparisonData={comparisonData}
                                                        language={userLang}
                                                    />
                                                );
                                            }
                                            if (analysisType === 'comparison_over_time_as_multiplebar_chart') {
                                                return (
                                                    <GroupedColumnChart
                                                        key={`${question.id}-grouped`}
                                                        question={question}
                                                        collection={collection}
                                                        comparisonData={comparisonData}
                                                        language={userLang}
                                                    />
                                                );
                                            }
                                            if (analysisType === 'comparison_over_time_as_column_100') {
                                                return (
                                                    <Column100Chart
                                                        key={`${question.id}-column-100`}
                                                        question={question}
                                                        collection={collection}
                                                        comparisonData={comparisonData}
                                                        language={userLang}
                                                    />
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                ))}
                            </div>
                        </CollapsibleSection>
                    ))}
                </section>
            </div>
        </div>
    );
};

export default DataCollectionReport;