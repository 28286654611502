import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

function ProRoute({ children }) {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Check subscription tier
  const tier = user?.entity?.subscription_tier;
  
  if (tier === 'professional') {
    return children;
  } else {
    // If founder, redirect to upgrade page
    return <Navigate to="/upgrade" />;
  }
}

export default ProRoute;
