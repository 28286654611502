import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Popover, Dialog, Transition, Disclosure } from '@headlessui/react';
import { UsersIcon, InboxArrowDownIcon, EyeIcon, ChevronDownIcon, LockClosedIcon, UserIcon, BuildingOfficeIcon, HeartIcon, CurrencyDollarIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import axios from '../../axios';
import { getTranslatedValue } from '../../utils/templateTranslations';

const ActiveCollections = ({ collections, refetchCollections }) => {
  const { t } = useTranslation();
  const [showTemplateDetails, setShowTemplateDetails] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const getAreaIcon = (area) => {
    switch (area) {
      case 'cybersecurity':
        return <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'data_privacy':
        return <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'organization':
        return <BuildingOfficeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'culture':
        return <HeartIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      case 'finance':
        return <CurrencyDollarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
      default:
        return <EllipsisHorizontalIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />;
    };
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userDataResponse = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      setUserData(userDataResponse.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  if (!userData) {
    return <div className="text-center">{t('Loading...')}</div>;
  }

  const userLang = userData.language
    ? userData.language.slice(0, 2)
    : 'en';

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatRespondentName = (respondent) => {
    if (respondent.was_deleted) {
      return respondent.user_name; // Already includes "(deleted)" from backend
    }
    return respondent.user_name;
  };

  const isCollectionUncompletable = (collection) => {
    return collection.is_uncompletable === true;
  };

  const getUncompletableMessage = (deletedUsers) => {
    if (!deletedUsers || deletedUsers.length === 0) return null;
    return t(
      deletedUsers.length === 1
        ? '{{user}} was deleted. This data collection cannot be completed.'
        : '{{users}} were deleted. This data collection cannot be completed.',
      {
        user: deletedUsers[0],
        users: deletedUsers.join(', ')
      }
    );
  };

  const getCompleteRespondentsList = (collection) => {
    // Start with existing respondents
    let respondents = [...(collection.respondents || [])];

    // Add deleted users from deleted_assignments if it's an individual collection
    if (collection.collection_type === 'individual' && collection.deleted_assignments) {
      Object.entries(collection.deleted_assignments).forEach(([part_id, assignments]) => {
        const deletedAssignees = assignments.assignees || [];

        deletedAssignees.forEach(deletedUser => {
          // Check if this deleted user already has a response (would be in respondents list)
          const existingRespondent = respondents.find(r =>
            r.was_deleted && r.id === deletedUser.id
          );

          if (!existingRespondent) {
            // Add as a new respondent if they don't have a response
            respondents.push({
              id: deletedUser.id,
              user_name: `${deletedUser.name}`,
              was_deleted: true,
              responded: false // They were deleted before responding
            });
          }
        });
      });
    }

    return respondents;
  };


  const calculateTotalSources = (collection) => {
    if (collection.collection_type === 'individual') {
      // For individual collections, include both active and deleted respondents
      const deletedUsers = Object.values(collection.deleted_assignments || {})
        .flatMap(assignments => assignments.assignees || []);
      return (collection.respondents?.length || 0) + deletedUsers.length;
    } else if (collection.collection_type === 'collaborative') {
      // Get unique users from active assignments
      const activeUsers = new Set();
      collection.parts?.forEach(part => {
        // Add each editor and submitter to the set
        part.editors?.forEach(editor => activeUsers.add(editor));
        part.submitters?.forEach(submitter => activeUsers.add(submitter));
      });

      // Get unique users from deleted assignments
      const deletedUsers = new Set();
      Object.values(collection.deleted_assignments || {}).forEach(assignments => {
        (assignments.editors || []).forEach(editor => deletedUsers.add(editor.name));
        (assignments.submitters || []).forEach(submitter => deletedUsers.add(submitter.name));
      });

      // Return total unique users (both active and deleted)
      return activeUsers.size + deletedUsers.size;
    }
    return 0;
  };

  const fetchTemplateDetails = async (templateId) => {
    try {
      const response = await axios.get(`/api/v1/data_collections/templates/${templateId}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      });
      setSelectedTemplate(response.data);
      setShowTemplateDetails(true);
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  const handleDelete = async (collectionId) => {
    if (window.confirm(t('Are you sure you want to delete this data collection? All responses will be permanently deleted.'))) {
      try {
        await axios.delete(`/api/v1/data_collections/${collectionId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
        });

        // Use the passed refetch function
        refetchCollections();
      } catch (error) {
        console.error('Error deleting collection:', error);
      }
    }
  };

  const handleProvideData = (collection) => {
    navigate(`/reports/respond/${collection.access_token}`);
  };

  const getCollectionTypeLabel = (collection) => {
    if (collection.collection_type === 'individual') {
      if (collection.is_anonymous) {
        return collection.is_mandatory
          ? t('Individual, anonymous (mandatory)')
          : t('Individual, anonymous (optional)');
      } else {
        return t('Individual, named');
      }
    } else if (collection.collection_type === 'collaborative') {
      return t('Collaborative');
    }
    return '';
  };

  const canUserAccessCollection = (collection) => {
    if (!userData) return false;
    return collection.created_by_id === userData.id ||
      collection.viewers.some(viewer => viewer.id === userData.id) ||
      collection.respondents.some(respondent => respondent.id === userData.id);
  };

  const canProvideData = (collection) => {
    if (!userData) return false;
    const respondent = collection.respondents.find(r => r.id === userData.id);
    return respondent && !respondent.responded;
  };

  const hasAlreadyResponded = (collection) => {
    if (!userData) return false;
    const respondent = collection.respondents.find(r => r.id === userData.id);
    return respondent && respondent.responded;
  };

  const filteredCollections = collections.filter(canUserAccessCollection);
  const sortedCollections = [...filteredCollections].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  const completedCollections = sortedCollections.filter(collection => collection.progress === 100);
  const activeCollections = sortedCollections.filter(collection => collection.progress < 100);

  return (
    <>
      <div className="grid grid-cols-1 gap-4">
        {activeCollections.map((collection) => (
          <div key={collection.id} className="bg-white rounded-lg shadow p-4">
            {/* Header */}
            <div className="flex flex-col space-y-2 mb-1">
              {/* Area indicator */}
              <div className="flex items-center text-sm text-gray-500">
                {getAreaIcon(collection.area)}
                <span className="ml-2">{t(`area_${collection.area}`)}</span>
              </div>
              {/* Title */}
              <button
                onClick={() => fetchTemplateDetails(collection.template_id)}
                className="text-left group"
              >
                <span className="text-lg font-semibold text-gray-900 group-hover:text-primary-color">
                  {getTranslatedValue(collection.data, userLang)}
                </span>
                <span className="text-sm font-normal text-gray-500 ml-2">
                  ({collection.period_value})
                </span>
              </button>
            </div>

            {isCollectionUncompletable(collection) && (
              <div className="mb-4 p-4 rounded-md bg-red-50 border border-red-200">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                      {t('This data collection cannot be completed')}
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>{getUncompletableMessage(collection.deleted_users)}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Dates */}
            <div className="text-sm text-gray-600 mb-2">
              {t('Started on')} <span className="font-medium">{formatDate(collection.starts_at)}</span>
              {collection.ends_at && (
                <>, {t('deadline on')} <span className="font-medium">{formatDate(collection.ends_at)}</span></>
              )}
            </div>

            {/* Sources, Type, and Recipients */}
            <div className="flex items-center mb-1 text-sm">
              {/* Sources (Respondents) */}
              <Popover className="relative inline-block">
                <Popover.Button className="flex items-center text-primary-color hover:text-secondary-color">
                  <UsersIcon className="h-4 w-4 mr-1" />
                  <span>
                    {t('Sources')}: {calculateTotalSources(collection)}
                  </span>
                </Popover.Button>

                <Popover.Panel className="absolute z-10 w-80 px-4 mt-3 transform -translate-x-1/2 left-1/2">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white p-3">
                      <div className="flow-root">
                        {collection.collection_type === 'individual' && (
                          getCompleteRespondentsList(collection).map((respondent, index) => (
                            <div key={index} className="flex items-center justify-between py-1">
                              <span className={`text-sm ${respondent.was_deleted ? 'text-gray-500 italic' : 'text-gray-900'}`}>
                                {formatRespondentName(respondent)}
                              </span>
                              {(
                                (collection.is_anonymous && collection.is_mandatory) ||
                                !collection.is_anonymous
                              ) && (
                                  <span className={`text-xs px-2 py-1 rounded-full ${respondent.responded
                                    ? 'bg-green-100 text-green-800'
                                    : respondent.was_deleted
                                      ? 'bg-red-100 text-red-800'
                                      : 'bg-yellow-100 text-yellow-800'
                                    }`}>
                                    {respondent.responded
                                      ? t('Responded')
                                      : respondent.was_deleted
                                        ? t('Deleted')
                                        : t('Pending')}
                                  </span>
                                )}
                            </div>
                          ))
                        )}
                        {collection.collection_type === 'collaborative' && (
                          collection.parts?.map((part, index) => {
                            // Get deleted users for this part
                            const deletedUsers = collection.deleted_assignments?.[part.id] || {};
                            const deletedEditors = deletedUsers.editors || [];
                            const deletedSubmitters = deletedUsers.submitters || [];

                            // Format editors list (combining active and deleted)
                            const formattedEditors = [
                              ...part.editors.map(editor => ({ name: editor, isDeleted: false })),
                              ...deletedEditors.map(editor => ({ name: editor.name, isDeleted: true }))
                            ].map(editor =>
                              editor.isDeleted
                                ? `${editor.name} (${t('deleted')})`
                                : editor.name
                            );

                            // Format submitters list (combining active and deleted)
                            const formattedSubmitters = [
                              ...part.submitters.map(submitter => ({ name: submitter, isDeleted: false })),
                              ...deletedSubmitters.map(submitter => ({ name: submitter.name, isDeleted: true }))
                            ].map(submitter =>
                              submitter.isDeleted
                                ? `${submitter.name} (${t('deleted')})`
                                : submitter.name
                            );

                            return (
                              <div key={index} className="py-1">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-900">
                                    {getTranslatedValue(part.name, userLang)}
                                  </span>
                                  <span className={`text-xs px-2 py-1 rounded-full ${part.status.status === 'responded'
                                    ? 'bg-green-100 text-green-800'
                                    : (deletedEditors.length > 0 || deletedSubmitters.length > 0)
                                      ? 'bg-red-100 text-red-800'
                                      : 'bg-yellow-100 text-yellow-800'
                                    }`}>
                                    {part.status.status === 'responded'
                                      ? t('Finished')
                                      : (deletedEditors.length > 0 || deletedSubmitters.length > 0)
                                        ? t('Blocked')
                                        : t('Pending')}
                                  </span>
                                </div>
                                <div className="text-xs text-gray-600">
                                  {t('Editors')}: {formattedEditors.join(', ')}
                                </div>
                                <div className="text-xs text-gray-600">
                                  {t('Submitters')}: {formattedSubmitters.join(', ')}
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>

              {/* Collection Type */}
              <span className="text-primary-color/60 ml-1">
                {getCollectionTypeLabel(collection)}
              </span>
            </div>

            {/* Recipients (Viewers) */}
            <div className="flex items-center mb-3 text-sm">
              <Popover className="relative inline-block">
                <Popover.Button className="flex items-center text-primary-color hover:text-secondary-color">
                  <EyeIcon className="h-4 w-4 mr-1" />
                  <span>
                    {t('Recipients')}: {collection.viewers?.length || 0}
                  </span>
                </Popover.Button>

                <Popover.Panel className="absolute z-10 w-64 px-4 mt-3 transform -translate-x-1/2 left-1/2">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white p-3">
                      <div className="flow-root">
                        {collection.viewers?.map((viewer, index) => (
                          <div key={index} className="py-1">
                            <span className="text-sm text-gray-900">{viewer.user_name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Popover>
            </div>

            {/* Progress Bar */}
            <div className="w-full">
              <div className="bg-gray-200 rounded-full h-2.5 mb-1">
                <div
                  className={`h-2.5 rounded-full transition-all duration-500 ${isCollectionUncompletable(collection) ? 'bg-red-500' : 'bg-secondary-color'
                    }`}
                  style={{ width: `${collection.progress}%` }}
                />
              </div>
              <div className="flex justify-between items-center text-xs text-gray-500">
                <span>{t('Progress')}</span>
                <span>{collection.progress}%</span>
              </div>
            </div>

            {/* Provide Data Button or Data Collection Finished Message */}
            <div className="mt-4">
              {collection.progress === 100 ? (
                <button
                  onClick={() => handleProvideData(collection)}
                  className="inline-flex items-center justify-center w-full py-2 px-4 text-gray-500 bg-gray-200 hover:text-gray-500 hover:bg-gray-100 rounded"
                >
                  {t('Data collection finished')}
                </button>
              ) : hasAlreadyResponded(collection) ? (
                <a
                  href={`/collection/${collection.id}`}
                  className="inline-flex items-center justify-center w-full py-2 px-4 text-gray-500 bg-gray-200 hover:text-gray-500 hover:bg-gray-100 rounded"
                >
                  {t('Already responded')}
                </a>
              ) : canProvideData(collection) && !isCollectionUncompletable(collection) ? (
                <button
                  onClick={() => handleProvideData(collection)}
                  className="inline-flex items-center justify-center w-full py-2 px-4 text-white bg-[#2A9D8F] hover:bg-secondary-color/80 rounded"
                >
                  <InboxArrowDownIcon className="h-4 w-4 mr-2" />
                  {t('Provide your data')}
                </button>
              ) : null}
            </div>
            {collection.created_by_id === userData?.id && (
              <div className="mt-2">
                <button
                  onClick={() => handleDelete(collection.id)}
                  className="group inline-flex flex-col items-center justify-center w-full py-2 px-4 border-1 border-red-400 text-red-400 rounded hover:bg-red-700 hover:text-white transition-colors"
                >
                  <span className="text-xs font-bold">{t('Cancel data collection')}</span>
                  <span className="text-xs">{t('Deletes all already provided responses!')}</span>
                </button>
              </div>
            )}
          </div>
        ))}

        {activeCollections.length === 0 && (
          <div className="text-center py-6 text-gray-500">
            {t('No active collections')}
          </div>
        )}
      </div>

      {/* Completed Collections Collapsible */}
      {completedCollections.length > 0 && (
        <div className="mt-6">
          <Disclosure>
            {({ open }) => (
              <>
                {/* Disclosure Button */}
                <Disclosure.Button className={`flex justify-between items-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 rounded-lg hover:bg-white focus:outline-none ${open ? 'bg-primary-color text-white hover:bg-primary-color/70' : ''
                  }`}>
                  <span>{t('See completed data collections')}</span>
                  <ChevronDownIcon
                    className={`w-5 h-5 transform text-gray-800 transition-transform duration-200 ${open ? 'rotate-180 text-white' : ''
                      }`}
                  />
                </Disclosure.Button>

                {/* Disclosure Panel */}
                <Disclosure.Panel className="mt-2">
                  <div className="grid grid-cols-1 gap-4">
                    {completedCollections.map((collection) => (
                      <div key={collection.id} className="bg-white rounded-lg shadow p-4">
                        {/* Header */}
                        <div className="flex justify-between items-start mb-2">
                          <button
                            onClick={() => fetchTemplateDetails(collection.template_id)}
                            className="text-left group"
                          >
                            <span className="text-lg font-semibold text-gray-900 group-hover:text-primary-color">
                              {getTranslatedValue(collection.data, userLang)}
                            </span>
                            <span className="text-sm font-normal text-gray-500 ml-2">
                              ({collection.period_value})
                            </span>
                          </button>
                        </div>

                        {/* Dates */}
                        <div className="text-sm text-gray-600 mb-3">
                          {t('Started on')}{' '}
                          <span className="font-medium">
                            {formatDate(collection.starts_at)}
                          </span>
                          {collection.ends_at && (
                            <>, {t('deadline on')}{' '}
                              <span className="font-medium">{formatDate(collection.ends_at)}</span></>
                          )}
                        </div>

                        {/* Sources, Type, and Recipients */}
                        <div className="flex items-center mb-1 text-sm">
                          <Popover className="relative inline-block">
                            <Popover.Button className="flex items-center text-primary-color hover:text-secondary-color">
                              <UsersIcon className="h-4 w-4 mr-1" />
                              <span>
                                {t('Sources')}: {collection.sources}
                              </span>
                            </Popover.Button>
                            <Popover.Panel className="absolute z-10 w-80 px-4 mt-3 transform -translate-x-1/2 left-1/2">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative bg-white p-3">
                                  <div className="flow-root">
                                    {collection.respondents?.map((respondent, index) => (
                                      <div
                                        key={index}
                                        className="flex items-center justify-between py-1"
                                      >
                                        <span className="text-sm text-gray-900">
                                          {respondent.user_name}
                                        </span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Popover>
                          <span className="text-primary-color/60 ml-1">
                            {getCollectionTypeLabel(collection)}
                          </span>
                        </div>

                        {/* Progress Bar */}
                        <div className="w-full">
                          <div className="bg-gray-200 rounded-full h-2.5 mb-1">
                            <div
                              className="bg-secondary-color h-2.5 rounded-full transition-all duration-500"
                              style={{ width: `${collection.progress}%` }}
                            />
                          </div>
                          <div className="flex justify-between items-center text-xs text-gray-500">
                            <span>{t('Progress')}</span>
                            <span>{collection.progress}%</span>
                          </div>
                        </div>

                        {/* Provide Data Button or Data Collection Finished Message */}
                        <div className="mt-4">
                          {collection.progress === 100 ? (
                            <button
                              onClick={() => handleProvideData(collection)}
                              className="inline-flex items-center justify-center w-full py-2 px-4 text-gray-500 bg-gray-200 hover:text-gray-500 hover:bg-gray-100 rounded"
                            >
                              {t('Data collection finished')}
                            </button>
                          ) : hasAlreadyResponded(collection) ? (
                            <a
                              href={`/collection/${collection.id}`}
                              className="inline-flex items-center justify-center w-full py-2 px-4 text-gray-500 bg-gray-200 hover:text-gray-500 hover:bg-gray-100 rounded"
                            >
                              {t('Already responded')}
                            </a>
                          ) : canProvideData(collection) ? (
                            <button
                              onClick={() => handleProvideData(collection)}
                              className="inline-flex items-center justify-center w-full py-2 px-4 text-white bg-[#2A9D8F] hover:bg-secondary-color/80 rounded"
                            >
                              <InboxArrowDownIcon className="h-4 w-4 mr-2" />
                              {t('Provide your data')}
                            </button>
                          ) : null}
                        </div>

                        {collection.created_by_id === userData?.id && (
                          <div className="mt-2">
                            <button
                              onClick={() => handleDelete(collection.id)}
                              className="group inline-flex flex-col items-center justify-center w-full py-2 px-4 border-1 border-red-400 text-red-400 rounded hover:bg-red-700 hover:text-white transition-colors"
                            >
                              <span className="text-xs font-bold">
                                {t('Cancel data collection')}
                              </span>
                              <span className="text-xs">
                                {t('Deletes all already provided responses!')}
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>


        </div>
      )}

      {/* Template Details Modal */}
      <Transition.Root show={!!showTemplateDetails} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowTemplateDetails(false)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  {selectedTemplate && (
                    <>
                      <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
                        {getTranslatedValue(selectedTemplate.name, userLang)}
                      </Dialog.Title>

                      {selectedTemplate.description && (
                        <p className="mt-2 text-sm text-gray-500">
                          {getTranslatedValue(selectedTemplate.description, userLang)}
                        </p>
                      )}

                      <div className="mt-4 space-y-4">
                        {selectedTemplate.template_data?.parts?.map((part, index) => (
                          <div key={part.id} className="text-sm text-gray-700">
                            <h4 className="font-semibold text-gray-800">
                              {t('Part')} {index + 1}: {getTranslatedValue(part.name, userLang)}
                            </h4>
                            {part.description && (
                              <p className="mt-1 text-gray-500">
                                {getTranslatedValue(part.description, userLang)}
                              </p>
                            )}
                            <ul className="list-disc list-inside mt-2 space-y-1">
                              {part.questions.map((question) => (
                                <li key={question.id}>
                                  {getTranslatedValue(question.text, userLang)}
                                  {question.type === 'boolean' && (
                                    <span> ({t('Answers')}: {
                                      getTranslatedValue(question.options, userLang).join(' / ')
                                    })</span>
                                  )}
                                  {question.type === 'rating' && (
                                    <span> ({t('Answers')}: {question.range[0]} - {question.range[1]})</span>
                                  )}
                                  {question.notes && (
                                    <div className="ml-6 mt-1 text-sm text-gray-500">
                                      {getTranslatedValue(question.notes_label, userLang)}
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>

                      <div className="mt-6 flex justify-end">
                        <button
                          onClick={() => setShowTemplateDetails(false)}
                          className="px-4 py-2 bg-primary-color text-white rounded-md hover:bg-secondary-color"
                        >
                          {t('Close')}
                        </button>
                      </div>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ActiveCollections;