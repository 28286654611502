// src/components/CompleteAccountSetup.js
import React, { useState } from 'react';
import axios from '../../axios';
import { useNavigate, useParams } from 'react-router-dom';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export default function CompleteAccountSetup() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put('/api/v1/users/password', {
        user: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: token,
        }
      });

      if (response.status === 200) {
        navigate('/account-setup-success');
      }
    } catch (error) {
      console.error('Error setting up account:', error.response?.data || error.message);
      setMessage(t('error_setting_up_account'));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="flex justify-center mb-6">
        <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-16 mb-4" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">{t('account_finish')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium text-tertiary-color">{t('password')}</label>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm px-3 py-2 pr-10"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-1/2 right-0 transform -y-1/2 px-3 flex items-center text-sm leading-5"              >
                {showPassword ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
              </button>
            </div>
            <div className="relative">
              <label htmlFor="passwordConfirmation" className="block text-sm font-medium text-tertiary-color">{t('confirm_password')}</label>
              <input
                type={showPasswordConfirmation ? 'text' : 'password'}
                name="passwordConfirmation"
                id="passwordConfirmation"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-color focus:ring-primary-color sm:text-sm px-3 py-2 pr-10"
              />
              <button
                type="button"
                onClick={togglePasswordConfirmationVisibility}
                className="absolute top-1/2 right-0 transform -y-1/2 px-3 flex items-center text-sm leading-5"              >
                {showPasswordConfirmation ? <EyeSlashIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
              </button>
            </div>
          </div>
          <div className="mt-6 flex justify-end gap-x-6">
            <button
              type="button"
              className="text-sm text-primary-color"
              onClick={() => navigate('/login')}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary-color px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
            >
              {t('save')}
            </button>
          </div>
          {message && <p className="mt-4 text-sm text-red-500">{message}</p>}
        </form>
      </div>
    </div>
  );
}
